/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
import { Howl } from 'howler';
import React from 'react';
import config from '../config/config';
import KEYBINDS from '../config/keybinds';
import AudioManager from '../lib/AudioManager';
import AppEnv from '../models/AppEnv.enum';
import SpeechResponse from '../models/SpeechResponse';

interface Props {
  audioSrc: string;
  text: string;
  repeat: number;
  timeout: number;
  onAnswer: (res: SpeechResponse) => void;
  onTimeout: () => void;
  onAudioEnd?: () => void;
  showButtons?: boolean;
  acceptYes?: boolean;
  acceptNo?: boolean;
  id?: string;
  facilitatorSession?: boolean;
}

const ActiveQuestion: React.FC<Props> = ({
  audioSrc,
  text,
  timeout,
  repeat,
  onAnswer,
  onTimeout,
  onAudioEnd = () => {},
  showButtons = true,
  acceptYes = true,
  acceptNo = true,
  id,
  facilitatorSession,
}) => {
  const debug = config.env === AppEnv.Dev;

  const [isLoading, setIsLoading] = React.useState(facilitatorSession ? false : true);
  const [canAnswer, setCanAnswer] = React.useState(true);

  React.useEffect(() => {
    AudioManager?.stop();
    setCanAnswer(true);
  }, [audioSrc]);

  React.useEffect(() => {
    function onKeyup(event: KeyboardEvent) {
      if (KEYBINDS.Yes.includes(event.code)) {
        onAnswer(SpeechResponse.Yes);
      }
      if (KEYBINDS.No.includes(event.code)) {
        onAnswer(SpeechResponse.No);
      }
    }

    window.addEventListener('keyup', onKeyup);

    return () => {
      window.removeEventListener('keyup', onKeyup);
    };
  }, [onAnswer]);

  React.useEffect(() => {
    if (facilitatorSession) {
      return;
    }

    let timeoutId: number;
    let startId: number;
    let audio: Howl;

    startId = window.setTimeout(() => {
      audio = AudioManager.createAudio({
        src: audioSrc,
        onplay: () => {},
        onend: () => {
          setCanAnswer(true);
          onAudioEnd();
          timeoutId = window.setTimeout(onTimeout, timeout);
        },
        onload: () => {
          setIsLoading(false);
        },
        onloaderror: () => {
          setIsLoading(false);
        },
      });
    }, 0);

    return () => {
      audio?.unload();

      if (startId) {
        window.clearTimeout(startId);
      }

      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [audioSrc, timeout, repeat, text, facilitatorSession]);

  const answerNo = React.useCallback(() => {
    if (facilitatorSession === false) {
      setIsLoading(true);
    }
    onAnswer(SpeechResponse.No);
  }, [onAnswer]);

  const answerYes = React.useCallback(() => {
    if (facilitatorSession === false) {
      setIsLoading(true);
    }
    onAnswer(SpeechResponse.Yes);
  }, [onAnswer]);

  return (
    <>
      {debug && (
        <div className="bg-cool-gray-900 flex p-2 space-x-2 text-white">
          <div>Debug {id}:::</div>
          <div>Repeat: {repeat}</div>
          <button type="button" className="hover:bg-cool-gray-700" onClick={onTimeout}>
            <span className="">Force timeout</span>
          </button>
        </div>
      )}
      <div className="relative flex flex-col flex-grow">
        <div
          className={`h3 absolute inset-0 z-10 flex items-center justify-center p-0 m-0 ${
            !isLoading && 'pointer-events-none invisible'
          }`}
        >
          Loading audio...
        </div>
        <p className="p-4 mx-auto font-sans text-lg text-center normal-case">{text}</p>
        {showButtons && (
          <div className="align-stretch flex justify-between flex-auto flex-grow mt-4 text-center">
            <button
              disabled={!acceptNo || !canAnswer}
              type="button"
              className="question-response__button--no"
              onClick={answerNo}
            >
              <span className="h2 text-red text-3xl">No</span>
            </button>
            <button
              disabled={!acceptYes || !canAnswer}
              type="button"
              className="question-response__button--yes"
              onClick={answerYes}
            >
              <span className="h2 text-green text-3xl">Yes</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(ActiveQuestion);
