import React from 'react';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import { useTherapySession } from '../contexts/TherapySessionContext';
import createTherapySessionRelaxation from '../data/therapySessions/createTherapySessionRelaxation';
import usePauseMenu from '../hooks/usePauseMenu';
import { RelaxationType } from '../models/RelaxationType.enum';
import SpeechResponse from '../models/SpeechResponse';
import ActiveQuestion from './ActiveQuestion';
import IconLongTimer from './icons/IconLongTimer';
import IconMediumTimer from './icons/IconMediumTimer';
import IconShortTimer from './icons/IconShortTimer';

const RelaxationIntro: React.FC<RouteComponentProps> = ({ history }) => {
  React.useEffect(() => ReactGA.pageview(routes.RelaxationIntro), []);

  const [relaxation, setRelaxation] = React.useState<string>();
  const { PauseMenu } = usePauseMenu({ showResume: true });
  const { therapySession } = useTherapySession();

  const onAudioEnd = React.useCallback(() => {
    history.push(routes.SessionTerminated);
  }, [history]);

  const onAnswer = React.useCallback(
    res => {
      if (res === SpeechResponse.Yes) {
        history.push(routes.RelaxationIntroNo);
      }
    },
    [history],
  );

  function getRelaxationSrc(type: RelaxationType): string {
    switch (type) {
      case RelaxationType.Short: {
        return config.audio.relaxationShortWithInvite;
      }
      case RelaxationType.Medium: {
        return config.audio.relaxationMediumWithInvite;
      }
      case RelaxationType.Long: {
        return config.audio.relaxationLongWithInvite;
      }
    }
  }

  const onSelectRelaxation = React.useCallback(
    (type: RelaxationType) => {
      const src = getRelaxationSrc(type);

      if (!src) return;

      createTherapySessionRelaxation(therapySession.id, type);

      window.setTimeout(() => {
        setRelaxation(src);
      }, config.relaxation.delay);
    },
    [therapySession],
  );

  return (
    <>
      <header className="pt-8 pb-2 text-center">
        <h1 className="h1 mx-auto mb-2">Relaxation Introduction</h1>
      </header>

      {relaxation ? (
        <>
          <div className="justify-stretch align-stretch flex flex-col flex-1 text-center">
            <ActiveQuestion
              text="Please listen to the audio that is playing."
              audioSrc={relaxation}
              repeat={0}
              timeout={config.relaxation.timeout}
              onAnswer={onAnswer}
              onAudioEnd={onAudioEnd}
              onTimeout={onAudioEnd}
              acceptYes
              acceptNo={false}
            />
          </div>
          <PauseMenu />
        </>
      ) : (
        <>
          <main className="justify-stretch align-stretch flex flex-col flex-1 p-4 text-center">
            <div className="sm:max-w-md mx-auto">
              <p className="mb-2">
                To begin our session, we will play a recording to help ease you into a meditative state.
              </p>
              <p>We have short, medium, and long recordings available.</p>
            </div>
            <h2 className="h3 md:mx-auto my-4">Please choose duration</h2>
            <div className="md:flex-row flex flex-col justify-start flex-auto text-center">
              <button
                type="button"
                className="md:inline-block md:mx-2 button button--primary md:w-auto md:h-36 block w-56 mx-auto mb-3 leading-none"
                onClick={() => onSelectRelaxation(RelaxationType.Short)}
              >
                <div className="block w-1/2 mx-auto mb-2 opacity-50">
                  <IconShortTimer />
                </div>
                Short
                <br />
                <small className="inline-block mt-2 text-xs leading-tight">About 2 minutes</small>
              </button>{' '}
              <button
                type="button"
                className="md:inline-block md:mx-2 button button--primary md:w-auto md:h-36 block w-56 mx-auto mb-3 leading-none"
                onClick={() => onSelectRelaxation(RelaxationType.Medium)}
              >
                <div className="block w-1/2 mx-auto mb-2 opacity-50">
                  <IconMediumTimer />
                </div>
                Medium
                <br />
                <small className="inline-block mt-2 text-xs leading-tight">About 7 minutes</small>
              </button>{' '}
              <button
                type="button"
                className="md:inline-block md:mx-2 button button--primary md:w-auto md:h-36 block w-56 mx-auto mb-3 leading-none"
                onClick={() => onSelectRelaxation(RelaxationType.Long)}
              >
                <div className="block w-1/2 mx-auto mb-2 opacity-50">
                  <IconLongTimer />
                </div>
                Long
                <br />
                <small className="inline-block mt-2 text-xs leading-tight">About 14 minutes</small>
              </button>
            </div>
          </main>
          <a href={config.api.root} className="session-pause-exit__button">
            <span className="h2 text-blue text-center">Exit</span>
          </a>
        </>
      )}
    </>
  );
};

export default RelaxationIntro;
